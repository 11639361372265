.GoogleReviews{

	display: block;
    clear: both;
    padding-bottom: 100px;
	
	/*
	=============== 
	Reviews
	===============
	*/
	main {
	  min-height: 100vh;
	  display: grid;
	  place-items: center;
	}
	.title {
	  text-align: center;
	  margin-bottom: 4rem;
	}
	.underline {
	  height: 0.25rem;
	  width: 5rem;
	  background: var(--clr-primary-5);
	  margin-left: auto;
	  margin-right: auto;
	}
	.container {
	  width: 80vw;
	  max-width: var(--fixed-width);
	}
	.review {
	  background: var(--clr-white);
	  padding: 1.5rem 2rem;
	  border-radius: var(--radius);
	  box-shadow: var(--light-shadow);
	  transition: var(--transition);
	  text-align: center;
	}
	.review:hover {
	  box-shadow: var(--dark-shadow);
	}
	.img-container {
	  position: relative;
	  width: 150px;
	  height: 150px;
	  border-radius: 50%;
	  margin: 0 auto;
	  margin-bottom: 1.5rem;
	}
	.person-img {
	  width: 100%;
	  display: block;
	  height: 100%;
	  object-fit: cover;
	  border-radius: 50%;
	  position: relative;
	}
	.quote-icon {
	  position: absolute;
	  top: 0;
	  left: 0;
	  width: 2.5rem;
	  height: 2.5rem;
	  display: grid;
	  place-items: center;
	  border-radius: 50%;
	  transform: translateY(25%);
	  background: var(--clr-primary-5);
	  color: var(--clr-white);
	}
	.img-container::before {
	  content: '';
	  width: 100%;
	  height: 100%;
	  background: var(--clr-primary-5);
	  position: absolute;
	  top: -0.25rem;
	  right: -0.5rem;
	  border-radius: 50%;
	}
	.author {
	  margin-bottom: 0.25rem;
	}
	.job {
	  margin-bottom: 0.5rem;
	  text-transform: uppercase;
	  color: var(--clr-primary-5);
	  font-size: 0.85rem;
	}
	.info {
	  margin-bottom: 0.75rem;
    padding: 10px 20px;
    max-width: 420px;
    margin-left: auto;
    margin-right: auto;
	

	.displayed-text{
		min-height:200px;
		display:block;
		position: relative;
	}

	}
	.prev-btn,
	.next-btn {
	  color: var(--clr-primary-7);
	  font-size: 1.25rem;
	  background: transparent;
	  border-color: transparent;
	  margin: 0 0.5rem;
	  transition: var(--transition);
	  cursor: pointer;
	}
	.prev-btn:hover,
	.next-btn:hover {
	  color: var(--clr-primary-5);
	}
	.random-btn {
	  margin-top: 0.5rem;
	  background: var(--clr-primary-10);
	  color: var(--clr-primary-5);
	  padding: 0.25rem 0.5rem;
	  text-transform: capitalize;
	  border-radius: var(--radius);
	  transition: var(--transition);
	  border-color: transparent;
	  cursor: pointer;
	}
	.random-btn:hover {
	  background: var(--clr-primary-5);
	  color: var(--clr-primary-1);
	}

	.read-more-button{
		padding:10px;
		position: absolute;
		bottom:0;
		left:50%;
		margin-left: -80px;
		width:160px;
		
		  border: none;
  color: #fff;
  cursor: pointer;
  font-size: 1rem;
  padding: 0.5rem 1rem;
  text-align: center;
  text-decoration: none;
  text-shadow: 1px 1px #333;
    background-color: #666;
  border-radius: 0.5rem;
  box-shadow: inset 1px 1px #888, inset -1px -1px #444;
	}

}	
